import html2canvas from "html2canvas";
export const qrcodeMixin = {
    data(){
        return{
            // 是否禁用按社区生成分享嘛
            makeOffDisabled:false,
            // 是否显示按社区生成分享码的div
            showMake:false,
            // 是否显示生成链接的
            linkMake:false,
            // 存储上传的背景图片
            posterImageUrl:'',
            // 当前选中的广州市码、街道码
            selectCode:'',
            // 是否禁选
            isDwonLoad:false
        }
    },
    methods:{
            // 点击清空街道按钮
            clearStree(){
                this.officialStree = ''
                this.officialProject = []
                this.makeOffDisabled = false
            },
             dealImage(base64, w,callback) {
            var newImage = new Image();
            var quality = 0.2;    //压缩系数0-1之间
            newImage.src = base64;
            newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
            var imgWidth, imgHeight;
            newImage.onload =  function () {
                imgWidth = this.width;
                imgHeight = this.height;
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                if (Math.max(imgWidth, imgHeight) > w) {
                    if (imgWidth > imgHeight) {
                        canvas.width = w;
                        canvas.height = w * imgHeight / imgWidth;
                    } else {
                        canvas.height = w;
                        canvas.width = w * imgWidth / imgHeight;
                    }
                } else {
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    quality = 0.2;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
                // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                // while (base64.length / 1024 > 150) {
                // 	quality -= 0.01;
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
                // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                // while (base64.length / 1024 < 50) {
                // 	quality += 0.001;
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
               callback(base64)
            }
            },
            // 获取上传链接地址
            getWebKit(file,name){
                this.$get(
                    "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
                    ).then(async(res) => {
                    if (res.code == 200) {
                    let fromData = new FormData()
                    let now = Date.parse(new Date()) / 1000;
                    fromData.append('name',now + name)
                    fromData.append('host',res.data.host)
                    fromData.append('key',res.data.key + now + name)
                    fromData.append('policy',res.data.encodedPolicy)
                    fromData.append('OSSAccessKeyId',res.data.accessId)
                    fromData.append('success_action_status','200')
                    fromData.append('signature',res.data.postSignature)
                    fromData.append('file',file)
                    this.posterImageUrl = res.data.host + res.data.key + now + name
                    // 跑上传接口
                    await this.uploadOss(fromData)
                    }
                    });
            },
            // 上传到oss接口
            async uploadOss(params){
                // 先上传背景图
                await this.$post(process.env.NODE_ENV !== 'production' ? 'http://liefeng.oss-cn-shenzhen.aliyuncs.com' :'https://liefeng.oss-cn-shenzhen.aliyuncs.com',params,{"Content-Type": "multipart/form-data" })
                // 等待上传成功之后下载压缩包
                await this.mergeQrBatch(this.selectCode)
            },
            // base64转blob
            dataURLtoBlob(dataurl) { 
                var arr = dataurl.split(','),
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: 'image/jpg' });
            },
            //将blob转换为file
            blobToFile(theBlob,){
                theBlob.lastModifiedDate = new Date();
                return theBlob;
            },
            // 点击宣传二维码
            downAllImage(){
                let that = this
                this.isDwonLoad = true
                html2canvas(this.$refs.refAllImage, { useCORS: true,
                ignoreElements:( _ele=>{
                    if(_ele.className.indexOf('qrdiv-image-div') > -1) return true
                    return false
                }),scale:6.2
                }).then(async function(
                    canvas
                ) {
                    let src = canvas.toDataURL('image/jpg');
                    var image = new Image();
                    // 解决跨域 Canvas 污染问题
                    image.setAttribute("crossOrigin", "anonymous");
                    image.onload = function() {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    var context = canvas.getContext("2d");
                    context.drawImage(image, 0, 0, image.width, image.height);
                    };
                    image.src = src;
                    that.dealImage(image.src,3508,((base)=>{
                    let blobList = that.dataURLtoBlob(base)
                    let file = that.blobToFile(blobList)
                    console.log('file',file);
                    that.getWebKit(file,'.jpg')
                    }))                 
                });
            },
            // 点击按社区生成分享码
            makeOfficialQRcodeList(){
                if (!this.officialRegion) {
                this.$Message.error({
                background: true,
                content: "请至少选择一个范围"
                });
                return;
            } 
            let code = ''
            if(this.officialRegion && this.officialRegion != ''){
                code = this.officialRegion
            }
            if(this.officialStree && this.officialStree != ''){
                code = this.officialStree
            }
            if(this.officialProject && this.officialProject.length){
                code = this.officialProject[this.officialProject.length - 1]
            }
            this.showMake = true
            this.linkMake = false
            this.selectCode = code
            return
            },
            // 生成多张二维码的接口
            async mergeQrBatch(orgCode){
            this.$Message.loading({
                content: "正在下载二维码，请稍等...",
                duration: 0,
            });
            this.makeOffDisabled = true
            let paramsList = {
                path:'pages/welcome/welcome',
                redirectPath:'/mainTaskPages/modules/activity/pages/detail/index',
                params:{
                code:this.infoCode
                }
            }
            await this.axios({
                    url:
                    window.vue.getProxy()["/syshequ"].target +
                    "/api/sytop/pc/wxqr/mergeQrBatch",
                    data:({
                        type: "ACTIVITY_SHARE",
                        orgLevel:"5",
                        orgCode,
                        bgImage:this.posterImageUrl,
                        x:450,
                        y:1550,
                        scaledX:1500,
                        scaledY:1400,
                        commonFontSize:50,
                        value:JSON.stringify(paramsList)
                    }),
                    method: "POST",
                    responseType: "blob",
                    headers: {
                    "Content-Type": "Application/json"
                    }
                }).then(res => {
                    this.isDwonLoad = false
                    this.makeOffDisabled = false
                    const blob = new Blob([res.data]);
                    const downloadElement = document.createElement("a");
                    const href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    downloadElement.download = "宣传二维码.zip";
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement);
                    window.URL.revokeObjectURL(href);
                    this.$Message.destroy();
                }).catch(err =>{
                    this.isDwonLoad = false
                    this.makeOffDisabled = false
                    this.$Message.destroy();
                    this.$Message.warning({
                        content:'下载超时',
                        background:true
                    })
                    return
                })
            },
     },
}