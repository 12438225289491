<template>
    <div>
        <Button type="primary" icon="ios-add" @click="add" class="addbtn">自定义编辑</Button>
        <div class="line">
            <span>或者引用模板</span>
        </div>
        <div style="margin:10px 0 10px 200px">
            <Input placeholder="按标题搜索模板" v-model.trim="templateName" style="width: 200px"></Input> 
            <Button type="primary" icon="ios-search" @click="searchFn" style="margin:0  10px">搜索</Button>
            <span style="color:#999999">点击引用即可查看编辑想要的容</span>
                
        </div>
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[10]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
        ></LiefengTable>
    </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable"
export default {
    components:{
        LiefengTable
    },
    // mixins:[myMixin],
    props:['columnCode'],
    data () {
        return {
            tableColumns:[
                {
                    title: '信息内容标题',
                    minWidth:500,
                    key: 'templateName'
                },
                {
                    title: '操作',
                    align: "center",
                    width:120,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                props: {
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                        // this.$emit("contrastFn",true)
                                        // this.$emit("changeAffairsType",'quote')
                                        this.quote(params.row.id)
                                    },
                                },
                                },
                                "引用"
                            ),
                        ]);
                    },
                },
                {
                    title: '创建时间',
                    key: 'gmtCreate',
                    align: "center",
                    width:200,
                    render:(h,params)=> {
                        return h(
                            'div',
                            {},
                            params.row.gmtCreate?this.$core.formatDate(new Date(params.row.gmtCreate),'yyyy-MM-dd'):''
                        )
                    }
                },
                {
                    title: '引用次数',
                    width:100,
                    align: "center",
                    render:(h,params)=> {
                        return h(
                            'div',
                            {},
                            `引用${params.row.useNum}次`
                        )
                    }
                },
            ],
            tableData: [
            ],
            loading:false,
            page:1,
            pageSize:10,
            tatal:0,
            templateName:"",
        }
    },
    methods:{
        getList(){
            this.loading = true
            this.$get('/info/api/pc/information/template/selectColumnPage',{
                columnCode:this.columnCode,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                templateName:this.templateName,
                page:this.page,
                pageSize:this.pageSize,
            }).then( res => {
                this.loading = false
                if( res.code == 200 ){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                }else{
                    this.$Message.error({
                        content:"数据获取失败",
                        background:true
                    })
                }
            })
        },
        // 查询
        searchFn(){
            this.getList()
        },
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 自定编辑
        add(){
            let obj =   {
                terminal: ["1", "3", "4"],
                time: (() => {
                  const end = new Date();
                  const start = new Date();
                  end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5);
                  return [start, end];
                })(),
                source: parent.vue.loginInfo.userinfo.orgName,
                recommend: '0',  //是否推荐,
                pushChannel: false, //小程序推送通知
              }
            // this.$emit('addShowNum')
            this.$emit('setFormData',obj)
            this.$emit("changeAddType",'add')
            this.$emit("addChangeStatusFn",true)
            console.log(1)
        },
        // 引用
        quote(templateId){
            this.$Message.loading({
                content: "数据正在加载中",
                duration: 0
            });
            this.$get('/info/api/pc/information/template/selectInformationTemplate',{
                staffId:parent.vue.loginInfo.userinfo.id,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                templateId,
            }).then( res => {
                this.$Message.destroy()
                if(res.code == 200){
                    let obj = JSON.parse(res.data.dataJson)
                    console.log(obj)
                    // if(obj.operationVo)
                    // if(obj.hasOwnProperty("operationVo"))

                    // delete obj.operationVo
                    // // if(obj.dismissList)
                    // // if(obj.hasOwnProperty("dismissList"))
                    // delete obj.dismissList
                    // // if(obj.dataScopeList)
                    // // if(obj.hasOwnProperty("dataScopeList"))
                    // delete obj.dataScopeList
                    // delete obj.id
                    // delete obj.gmtCreate
                    // obj.endTime = this.$core.formatDate(new Date(obj.endTime.time),'yyyy-MM-dd hh:mm:ss')
                    // obj.startTime = this.$core.formatDate(new Date(obj.startTime.time),'yyyy-MM-dd hh:mm:ss')
                    
                    this.$emit("addChangeStatusFn",true)
                    this.$emit('setFormData',obj)
                    this.$emit("changeAddType",'quote')
                    this.$emit('quoteData')
                    
                }else{
                    this.$Message.error({
                        content:"引用失败",
                        background:true
                    })
                }
            })

        }

    },
    watch:{
        // columnCode(val){
        //     this.getList(val)
        // }
    }
}
</script>
 
<style lang="less" scoped>
    .header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom:10px ;
    }
    /deep/.ivu-table-header{
        display: none;
    }
    .addbtn{
        margin-left: 200px;
        width: 300px;
        height: 75px;
        font-size: 30px;
    }
    .line{
        position: relative;
        margin: 20px 0;
        width: 100%;
        height: 0px;
        border-bottom: 1px solid #d4d4d4;
        span{
            display: inline-block;
            width: 100px;
            height:30px;
            color: #999999;
            line-height: 30px;
            background-color: #fff;
            position: absolute;
            top: -15px;
            left: 500px;
            text-align: center;
        }
    }
</style>